@import ".././../../../styles/config/variables";

.traveller {
  background: $travellers-bg;
  padding-top: 10px;

  h5 {
    font-weight: bold;
    margin-bottom: 15px;
  }
}

.trip-type-container {
  margin-bottom: 20px;
  padding: 0 15px;
  text-align: center;

  .trip-type-text {
    white-space: nowrap;
  }

  h1 > div {

    @media all and (max-width: $screen-xs) {
      font-size: 0.8em;
    }

    @media all and (max-width: 370px) {
      font-size: 0.7em;
    }
  }

  h4 > div {
    @media all and (max-width: 370px) {
      font-size: 0.7em;
    }
  }

  .trip-type-btn-container {
    padding: 0;
  }

  input {
    display: block;
    opacity: 0;
  }

  label {
    padding: 0;
    width: 100%;
  }

  .single {
    border-top-left-radius: $radius-small;
    border-bottom-left-radius: $radius-small;
  }

  .annual {
    border-top-right-radius: $radius-small;
    border-bottom-right-radius: $radius-small;
  }

  .trip-type-btn {
    background: $white-btn-gradient;
    border: $white-btn-border;
    padding: 15px;
    text-align: center;
    transition: $transition;

    @media all and (max-width: 320px) {
      height: 135px;
    }

    //&:hover {vari
    //  box-shadow: $btn-inset-shadow;
    //}

    svg {
      font-size: 1em;
      width: 45px;
      height: 50px;
    }

    p {
      font-weight: lighter;
      text-transform: uppercase;
      font-size: .8em;
    }
  }

  input:checked + .trip-type-btn {
    background: $blue-btn-gradient;
    border: $blue-btn-border;
    color: $color4;
    position: relative;

    svg {
      fill: $color4;
    }

    &:after {
      content: '';
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      left: 47%;
      bottom: -2px;
    }
  }

  input:focus + .trip-type-btn {
    box-shadow: inset 0 0 16px $color8;
  }
}

.group-type-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media all and (max-width: $screen-xs) {
  label[for=start-date], label[for=end-date], .date-picker {
    width: 100% !important;
  }
}

.radio-button-text {
  svg {
    margin-bottom: 5px;
  }
}

.section-title > h2 {
  @media all and (max-width: 370px) {
    font-size: 1.3em;
  }
}
