@import '../../../../../styles/config/variables';

.card-option-button {
  @media all and (max-width: $screen-xs) {
    font-size: 14px;
    width: 150px;

    svg {
      display: none;
    }
  }

  @media all and (max-width: $screen-xxs) {
    font-size: 12px;
    width: 125px;

  }
}

.show-on-mobile {
  display: none;
  @media all and (max-width: $screen-sm) {
    display: block;
  }

}

.text-aligning {
  @media all and (max-width: $screen-lg) {
    padding-left: 0;
  }
  @media all and (min-width: $screen-lg) {
    padding-left: 65px !important;
  }

}

.renewal-buttons {
  font-size: 16px;
  font-weight: normal;
  width: 300px !important;

  @media all and (max-width: $screen-xs) {
    font-size: 14px;
  }
}

.payment-link {
  text-decoration: underline;
  cursor: pointer;
  color: $color3;
}
