@import '../../../../../../styles/config/variables';

.modal-content {

  .modal-header {
    background: $modal-header-bg;
    color: $modal-header-color;
    border-top-right-radius: $radius-small;
    border-top-left-radius: $radius-small;

    h4 {
      color: $modal-header-color;
    }

    .close {
      color: $modal-header-color;
    }
  }

}

.info-btn {
  cursor: pointer;
}
