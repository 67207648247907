@import '../../styles/config/variables';

.opt-in-modal-body {
  padding: 0;
}

.optIn-details-info {
  background-color: #edf7ff;
  border-left: solid 4px $color1;
  padding: 10px 35px;

  svg {
    padding-top: 4px;
  }
}

.radio-text {
  padding-left: 15px;
}

.renewal-selection {
  background-color: #edf7ff !important;
}

.info-icon {
  position: absolute;
  left: 15px;
}

.radio-group-opt {
  display: flex;
  margin: 5px 30px 20px 30px;

  .radio-opt {
    width: 50%;

    #optIn, #optOut {
      transform: scale(1.9);
    }

    input:checked + .radio-opt-text-auto {
      background-color: $input-checked-color !important;
    }

    .radio-opt-text-auto {
      color: $input-checked-color;
      font-size: 1.7rem;
    }

    .radio-opt-text-manual {
      font-size: 1.7rem;
    }
  }
}

.radio-group-renewal {
  .radio-opt:first-child {
    .radio, .checkbox {
      text-align: left;
      margin-bottom: 5px;

      input {
        display: block;
        opacity: 0;
      }

      label {
        padding-left: 0;
        font-weight: bold;

        div {
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-bottom: -6px;
          border: 2px solid $input-checked-color;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            font-size: 1.2em;
            color: $input-checked-color;
            text-align: center;
            padding: 4px 2px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            position: absolute;
            left: -2px;
            top: -2px;
            transition: 0.2s ease;
            line-height: 17px;
          }
        }

        input:checked + div::after {
          opacity: 1;
          background-color: $input-checked-color;
          border: 2px solid #edf7ff;
        }

        input:focus + div {
          box-shadow: 0 0 3px $input-button-color;
        }
      }
    }

    .control-label {
      text-align: center !important;
    }
  }

  .radio-opt:nth-child(2) {
    .radio, .checkbox {
      text-align: left;
      margin-bottom: 5px;

      input {
        display: block;
        opacity: 0;
      }

      label {
        padding-left: 0;
        font-weight: bold;

        div {
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-bottom: -6px;
          border: 2px solid $input-button-color;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            font-size: 1.2em;
            color: $input-checked-color;
            text-align: center;
            padding: 4px 2px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            position: absolute;
            left: -2px;
            top: -2px;
            transition: 0.2s ease;
            line-height: 17px;
          }
        }

        input:checked + div::after {
          opacity: 1;
          background-color: $input-checked-color;
          border: 2px solid #edf7ff;
        }

        input:focus + div {
          box-shadow: 0 0 3px $input-button-color;
        }
      }
    }

    .control-label {
      text-align: center !important;
    }
  }
}
