#env_banner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background-color: #00BCD4;
  color: #fff;
  font-size: 0.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  > span {
    display: inline-block;
    padding: 3px;
  }

  &.env-qa {
    background-color: #673AB7;
  }

  &.env-uat {
    background-color: #FFEB3B;
    color: #000000;
  }
}
