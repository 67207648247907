@import ".././../../../../styles/config/variables";

.important-idol {
  background: $important-idol-message-color;
  color: $color10;
  padding: 10px 15px;

  strong {
    color: $color10 !important;
  }

  p {
    font-weight: normal !important;
    padding: 10px 0;
    margin: 0;
  }

  .more-info {
    color: $color1;
    cursor: pointer;
  }
}

.quote-summary {
  border-radius: $radius-small;
  background: $quote-summary-bg;
  position: relative;
  margin-top: 15px;

  @media screen and (min-width: $screen-md) {
    margin-top: 0;
  }

  h4 {
    padding: 15px 15px 0;
  }

  h4, h3 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 15px;
  }

  ul {
    margin-bottom: 0;

    li:first-of-type {
      margin-top: 0;
    }

    li:last-of-type {
      margin-bottom: 0;
    }
  }
}

.traveller-conditions {
  color: $quote-summary-traveller-conditions-highlight-color;
  font-style: italic;
}

.your-premium {
  margin-top: 15px;
  background: $quote-summary-premium-bg;
  color: $quote-summary-premium-color;
  font-size: 1.2em;
  padding: 15px;

  .selected-premium {
    float: right;
    font-weight: bold;
  }
}

#policyDeclaration {
  padding: 15px 0 15px 0 !important;
}
