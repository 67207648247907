@import '../../../../styles/config/variables';

.new-header {
  padding: 55px 20px 10px 20px;
  border: none;
  border-bottom: 1px solid $color15;
  color: $header-default-color;
  background-color: #FFF;

  header {
    padding: 20px 0 20px 0 !important;
  }

  .header-container {
    padding: 0 !important;
    @media all and (max-width: $screen-sm) {
      width: 100% !important;
    }

    .header-flex-box {
      display: flex;
      align-items: center;

      @media all and (max-width: $screen-sm) {
        display: block;
      }
    }
  }

  .logo-container {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 430px;

    @media all and (max-width: $screen-xs) {
      display: inline-block;
      width: 300px;
      text-align: left;
    }

    .channel-logo-solo {
      height: 64px;
      display: flex;
      align-items: center;

      @media all and (max-width: $screen-sm) {
        margin: auto;
      }

      img {
        width: 200px;
      }
    }

    .logo-link {
      //cursor: pointer;
      position: relative;
      max-width: 200px;
      max-height: 90px;
      float: left;

      img {
        width: 200px;
      }

      @media all and (max-width: $screen-md) {
        width: 100px;
        padding: 15px 0;
        float: left;
        img {
          width: 100px;
        }
      }

    }

    .logo-title {
      font-size: 2.5em;
      position: relative;

      @media all and (max-width: $screen-md) {
        width: 100%;
        padding: 17px 0 15px 0;
        font-size: 2rem;
      }
    }

    .logo-divider {
      border-left: 1px solid $headings-color;
      width: 1px;
      margin: 0 15px;
      min-height: 60px;
      position: relative;
      float: left;

      @media all and (max-width: $screen-xs) {
        display: block;
      }
    }

    .agg-logo {
      float: left;

      @media all and (max-width: $screen-xs) {
        padding: 15px 0;
        width: 100%;
        float: none;
      }

      img {
        max-height: 90px;
        max-width: 200px;
      }
    }
  }

  .extra-info {
    float: right;
    min-height: 64px;
    text-align: right;


    @media all and (max-width: $screen-sm) {
      margin: auto;
      float: none;
    }

    .info-header {
      display: flex;
      float: right;
      min-height: 64px;
      align-items: center;

      .phone {
        font-size: 1.5em;
        float: right;
        font-weight: bold;
        color: $headings-color;
        background: none;
        border: 1px solid $headings-color;
        padding: 5px;
        border-radius: $radius-small;
        cursor: pointer;

        @media all and (max-width: $screen-sm) {
          margin: auto;
          float: none;
        }

        svg {
          font-size: 1em;
          margin-right: 5px;
          vertical-align: middle;
        }
      }

      @media all and (max-width: $screen-sm) {
        margin: auto;
        float: none;
      }
    }
  }

  .phone-btn {
    font-size: 1.4em;

    svg {
      transform: rotateY(180deg);
      margin-right: 5px;
    }
  }

  .modal-body {
    a {
      text-decoration: none;
    }
  }

  .opening-hours {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 5px 0;

      &:last-child {
        font-size: .8em;
        color: $alternative-headings-color;
        margin-top: 15px;
        line-height: 140%;
      }
    }
  }


  .navbar-nav {

    @media all and (max-width: $screen-md) {
      font-size: 1.2rem;
    }

    li {

      a {
        background-color: transparent;
        text-decoration: none;
        border-bottom: #FFF solid 2px;


        &:hover {
          border-bottom: $color15 solid 2px;
          text-decoration: none;
        }

        &.active {
          border-bottom: $color15 solid 2px;
          text-decoration: none;
        }
      }

    }

  }

  .navbar-toggle {
    float: right;
    color: $color-primary;
    border: none;

    @media all and (max-width: $screen-xs) {
      position: absolute;
      right: 20px;
      top: 60px;
    }

    .icon-bar {
      background-color: $color-primary;
    }
  }

  .navbar-collapse {
    border: none;
  }

  .navbar-header {
    @media all and (max-width: $screen-xs) {
      text-align: center;
    }
  }
}

