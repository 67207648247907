@import ".././../../../styles/config/variables";

.policy-number {
  font-size: 1.2em;
  color: $complete-policy-number-highlight-color;

  @media all and (max-width: $screen-smx) {
    font-size: 1em;
  }
  @media all and (max-width: 375px) {
    font-size: 0.8em;
  }
}

.policy-documents {
  margin-top: 30px;

  .btn-documents {
    padding: 10px 10px !important;
    background: $gradient2;
    color: $white-bg;
    width: 100%;
    font-weight: normal;
    font-size: 1.4rem;

    @media all and (max-width: $screen-smx) {
      font-size: 1rem;
    }
  }
}

.page-complete {
  .section {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.purchase-complete-text {
  font-size: 0.9em;
  font-weight: bold;
}

.link {
  color: #337ab7;
  cursor: pointer;
}
