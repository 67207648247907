@import '../../../../styles/config/variables';

header {
  padding: 20px;
  color: $header-default-color;
  display: flex;
  align-items: center;
}

.header-container {
  @media all and (max-width: $screen-sm) {
    width: 100% !important;
  }

  .header-flex-box {
    display: flex;
    align-items: center;

    @media all and (max-width: $screen-sm) {
      display: block;
    }
  }
}

.logo-container {
  text-align: center;
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  max-width: 430px;

  .channel-logo-solo {
    height: 64px;
    display: flex;
    align-items: center;

    img {
      width: 200px;
    }
  }

  .logo-link {
    //cursor: pointer;
    position: relative;
    max-width: 200px;
    max-height: 90px;
    float: left;

    @media all and (max-width: $screen-sm) {
      img {
        max-width: 120px;
      }
    }

    @media all and (max-width: $screen-xs) {
      width: 100%;
      padding: 15px 0;
      float: none;
      margin: auto;

      img {
        max-width: 80px;
      }
    }

    img {
      width: 200px;
    }
  }

  .logo-title {
    font-size: 2.5em;
    position: relative;

    @media all and (max-width: $screen-sm) {
      font-size: 2rem;
    }

    @media all and (max-width: $screen-xs) {
      width: 100%;
      padding: 15px 0;
      font-size: 1.4rem;
      margin: auto;
    }
  }

  .logo-divider {
    border-left: 1px solid $headings-color;
    width: 1px;
    margin: 0 15px;
    min-height: 60px;
    position: relative;
    float: left;

    @media all and (max-width: $screen-xs) {
      margin: 0 10px;
    }
  }

  .agg-logo {
    float: left;
    position: relative;

    img {
      max-height: 90px;
      max-width: 200px;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    @media all and (max-width: $screen-sm) {
      img {
        max-width: 120px;
      }
    }

    @media all and (max-width: $screen-xs) {
      padding: 15px 0;
      width: 100%;
      float: none;

      img {
        max-width: 80px;
      }
    }

  }
}

.extra-info {
  float: right;
  min-height: 64px;
  text-align: right;

  .info-header {
    display: flex;
    float: right;
    min-height: 64px;
    align-items: center;

    .phone {
      font-size: 1.5em;
      float: right;
      font-weight: bold;
      color: $headings-color;
      background: none;
      border: 1px solid $headings-color;
      padding: 5px;
      border-radius: $radius-small;
      cursor: pointer;

      @media all and (max-width: $screen-sm) {
        font-size: 1.2em;
        margin: auto;
        float: none;
      }

      @media all and (max-width: $screen-xs) {
        font-size: 0.8em;
      }

      svg {
        font-size: 1em;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    .agg-phone {
      font-size: 1.5em;
      float: right;
      font-weight: bold;
      color: $headings-color;
      background: none;
      border: 1px solid $headings-color;
      padding: 5px;
      border-radius: $radius-small;
      cursor: pointer;

      @media all and (max-width: $screen-sm) {
        font-size: 1.2em;
        margin: auto;
        float: none;
      }

      @media all and (max-width: $screen-xs) {
        font-size: 0.8em;
      }

      @media all and (max-width: $screen-xxs) {
        .agg-phone-text {
          display: none;
        }
      }

      svg {
        font-size: 1.2em;
        transform: rotateY(180deg);
      }
    }

    @media all and (max-width: $screen-sm) {
      margin: auto;
      float: none;
    }
  }
}

.phone-btn {
  font-size: 1.4em;

  svg {
    transform: rotateY(180deg);
    margin-right: 5px;
  }
}

.modal-body {
  a {
    text-decoration: none;
  }
}

.opening-hours {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 5px 0;
  }

  &.special {
    li {
      &:last-child {
        font-size: .8em;
        color: $alternative-headings-color;
        margin-top: 15px;
        line-height: 140%;
      }
    }
  }
}
