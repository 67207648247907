@import "../../styles/config/variables";

.layout {
  background-color: transparent;
}

// Global

html, body {
  background: $page-bg;
  //font-family: $font-family-base;
}

h1, h2, h3, h4, h5, h6 {
  color: $headings-color;
}

.emergency-messaging {
  h1, h2, h3, h4, h5, h6 {
    color: $color10;
  }

  p {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.internal-page {
  padding-bottom: 20px;
  background: $page-bg;
  position: relative;
  z-index: 5;
  margin-bottom: 110px;

  @media all and (max-width: $screen-xs) {
    margin-bottom: 50px;
  }
}

.margin-top {
  margin-top: 15px;
}

.pointer {
  cursor: pointer;
}

main > div > .container {
  // 100vh - header - footer
  min-height: calc(100vh - 155px - 90px);
}

// Inputs

.control-label {
  text-align: left !important;
}

.icon-fill {
  fill: $input-button-selected-bg;
}

.radio-group {
  .radio, .checkbox {
    text-align: left;
    margin-bottom: 5px;

    input {
      display: block;
      opacity: 0;
    }

    label {
      padding-left: 0;
      font-weight: bold;

      div {
        display: inline-block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $white-bg;
        margin-right: 10px;
        margin-bottom: -3px;
        border: 1px solid $input-button-selected-bg;
        position: relative;
        z-index: 1;

        &:after {
          opacity: 0;
          //content: "\F00C";
          //font-family: "FontAwesome";
          content: '';
          background: url("../Quote/img/checkbox-tick-white.svg") 4px 0 no-repeat, $btn-primary-gradient;
          background-size: 70% 100%;
          font-size: 1.2em;
          color: white;
          text-align: center;
          padding: 4px 2px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          position: absolute;
          left: -1px;
          top: -1px;
          transition: 0.2s ease;
          line-height: 17px;
        }
      }

      input:checked + div::after {
        opacity: 1;
      }

      input:focus + div {
        box-shadow: 0 0 3px $input-button-color;
      }
    }
  }

  .control-label {
    text-align: center !important;
  }
}

.radio-horizontal {
  input[type="radio"] {
    margin-right: 10px;
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-bottom: 10px;
}

.radio-horizontal .radio, .checkbox {
  padding-top: 0;

  input {
    display: block;
    opacity: 0;
  }

  label {
    padding-left: 0;
    font-weight: bold;

    div {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: $white-bg;
      margin-right: 10px;
      margin-bottom: -6px;
      border: 1px solid #B8B8CC;
      position: relative;
      z-index: 1;

      &:after {
        opacity: 0;
        //content: "\F00C";
        //font-family: "FontAwesome";
        content: '';
        background: url("../Quote/img/checkbox-tick.svg") center center no-repeat;
        font-size: 1.5em;
        color: $input-checked-color;
        text-align: center;
        padding: 0;
        width: 32px;
        height: 37px;
        position: absolute;
        left: 1px;
        bottom: -3px;
        transition: 0.2s ease;
        line-height: 17px;
      }
    }

    input:checked + div::after {
      opacity: 1;
    }

    input:focus + div {
      box-shadow: 0 0 3px $input-button-color;
    }
  }
}

// Tables

table {
  width: 100%;
  border-collapse: collapse;
  background: $table-bg;

  thead {
    font-weight: bold;
    color: $headings-color;
    border-bottom: 1px solid $table-head-border-color;
  }

  td {
    padding: 15px;
  }

  tr {
    border-bottom: 1px solid $table-head-border-color;

    &:nth-child(odd) {
      background-color: $table-alt-bg;
    }
  }
}

li {
  margin: 10px 0;
}

// butons

.btn-bar {
  height: 31px;

  &--auto-height {
    height: auto;
  }
}

.btn-lg {
  padding: 15px 50px;
}

.btn {
  position: relative;
  font-weight: bolder;
  transition: $transition;

  //&:hover {
  //  box-shadow: $btn-inset-shadow;
  //}
}

.btn-primary {
  border: 1px solid $btn-primary-border;
  background: $btn-primary-gradient;
  border-radius: $radius-small !important;

  &:disabled {

    &:active {
      background: $btn-primary-gradient;
    }
  }
}

.flex-btn-bar {
  display: flex;
  justify-content: space-between;

  .btn {
    @media all and (max-width: $screen-sm) {
      padding: 15px 25px;
    }
  }
}


// Fields

.form-control, .Select-control {
  border: 1px solid $input-border-color;
  border-radius: $radius-small !important;
  font-size: 16px !important;
}

.manual-field-label {
  margin-bottom: 5px;
}

.full-name-fields {
  .form-control, .Select-control {
    @media all and (max-width: $screen-sm) {
      margin-bottom: 15px;
    }
  }
}

// postcode and address

.input-group {
  .form-control {
    border-radius: $radius-small 0 0 $radius-small !important;
  }

  .input-group-btn {

    .btn-postcode {
      padding-bottom: 7px;
      height: 34px;
      border-radius: 0 $radius-small $radius-small 0 !important;
      margin: 0 !important;
    }
  }
}

.line-top {
  border-radius: $radius-small $radius-small 0 0 !important;
  border-bottom: 1px solid transparent;
}

.line-middle {
  border-radius: 0 !important;
  border-bottom: 1px solid transparent;
}

.line-bottom {
  border-radius: 0 0 $radius-small $radius-small !important;
}

// Content

.section-group {
  border: 1px solid $section-border-color;
  border-radius: $radius-small;
  margin-bottom: 15px;
  background: $section-bg;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);

  .section {
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    border-top: 1px solid $divider-color;

    &:first-child {
      border-top: none;
      border-top-left-radius: $radius-small;
      border-top-right-radius: $radius-small;
      border-bottom-color: $divider-color;
    }

    &:last-child {
      border-bottom-right-radius: $radius-small;
      border-bottom-left-radius: $radius-small;
      border-top-color: $divider-color;
      border-bottom-color: 1px solid $section-border-color;
    }
  }

  .summary-section {
    padding: 15px;
    border-radius: 0;
    margin-bottom: 0;
    border: none;
  }

  .sub-benefit {
    font-size: 0.8em;
  }

  .white-section {
    background: $white-bg;
    border-radius: 0 0 $radius-small $radius-small;
    border: none;
    margin-bottom: 0;
    color: $headings-color;

    &:not(.no-divider) {
      border-top: 1px solid $divider-color;
    }
  }
}

.section {

  //border: 1px solid $section-border-color;
  //border-radius: $radius-small;
  background: $section-bg;
  margin-bottom: 15px;
  padding: 15px;

  &.no-top-border {
    border-top: unset;
  }

  strong {
    color: $headings-color;
  }

  h4, h3 {
    color: $headings-color !important;
    margin-bottom: 0 !important;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  label {
    font-weight: normal !important;
  }
}

.section-important {
  border-radius: $radius-small;
  background: $section-important-bg;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 15px;

  &.no-top-border {
    border-top: unset;
  }

  strong {
    color: $headings-color;
  }

  h4, h3 {
    color: $headings-color !important;
    margin-bottom: 0 !important;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  label {
    font-weight: normal !important;
  }
}

.section-border-top {
  border-top: 1px solid $section-border-color;
}

.section-inside-section {
  padding-top: 0 !important;
}

.white-section {
  border: 1px solid $section-border-color;
  background: $white-bg;
  border-radius: $radius-small;
  margin-bottom: 15px;
  padding: 15px;

  p {
    font-weight: bold !important;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.section-title {
  border-bottom: 1px solid $divider-color;
  margin-bottom: 15px;

  .purchase-complete {
    color: $color1;
    margin-bottom: 25px;
  }
}

.section-title-no-border {
  margin-bottom: 15px;
}

.important-inline-section {
  background: $section-important-bg;
  color: $color16;
  padding: 15px;

  .text {
    padding-bottom: 10px;
  }
}

.important {
  color: $important-message-color;
  padding: 0 15px;

  strong {
    color: $important-message-color !important;
  }

  p {
    font-weight: normal !important;
  }
}

.info-box {
  color: $color1;
  background-color: $color20;
  padding: 5px;
  margin: 5px;

  strong {
    color: $color1;
  }
}

.important-medical {

}

.info-note {
  color: #4F4F4F;
  padding: 0px 0px;
  font-style: italic;
  font-size: 0.75em;
  font-weight: bold;
}

.padding-0 {
  padding: 0 !important;
}

//cookie container

.cookie-and-privacy-container {
  background: $cookieAndPrivacyBg;
  color: $cookieAndPrivacyColor;
  position: relative;

  .container {
    position: relative;

    @media all and (max-width: $screen-sm) {
      padding: 0;
    }
  }

  a {
    color: $cookieAndPrivacyLinkColor;
    text-decoration: none;
    margin-right: 5px;
  }

  .cookie-and-privacy-message {
    padding: 15px 0;
    width: 80%;
    float: left;

    @media all and (max-width: $screen-sm) {
      width: 100%;
      padding: 15px;
    }
  }

  .cookie-and-privacy-btn {
    background: $cookieAndPrivacyButtonBg;
    width: 130px;
    right: 15px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    padding: 10px 0;

    @media all and (max-width: $screen-sm) {
      width: 100%;
      right: 0;
      position: relative;
    }

    .cookie-btn-content {
      //border: 1px solid orange;
      margin: auto;
      display: flex;
      align-items: center;
    }

    svg {
      //border: 1px solid orange;
      font-size: 1.8em;
      opacity: .8;
      margin-left: -5px;
    }
  }
}

.fade-out {
  animation: fadeOut 1s ease;
  opacity: 0;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.popover-content {
  max-height: 500px;
  overflow: auto;
}


.icon-cal {
  background: $headings-color;
  border-radius: 0 $radius-small $radius-small 0;
  width: 34px;
  height: 34px;
  padding: 0;
  cursor: pointer;

  img {
    width: 34px;
  }
}

.alternative-date-picker {
  position: relative;

  .field {
    width: 85%;
    float: left;
  }

  .icon {
    width: 34px;
    float: right;
  }

  div:first-child .form-control {
    border-radius: $radius-small 0 0 $radius-small !important;
  }

  .form-control {
    border-radius: 0 !important;
    text-align: left;
  }
}

// Font atoms

.primary-text {
  color: $color-primary;
}

.amt-message {
  margin-top: 15px;
  font-size: 14px;
  font-style: italic;
}


// Icons

.icon {
  vertical-align: middle;
}

// CARD

.admiral-card {
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);

  .header {
    background-color: $color-primary;
    color: #FFF;
    font-weight: normal;
    padding: 15px;
    font-size: 16.8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .body {

    .section-divider {
      border-bottom: 1px solid $color-quaternary;

      .medical-section-group {
        margin: 0;
      }
    }

    .row {

      &:not(.billing) {
        padding: 15px 0 15px 0;
      }

      &:not(.card-panel):not(.dynamic-row):not(.billing) {
        border-bottom: 1px solid $color-quaternary;
      }

      &.clickable {
        cursor: pointer;
      }
    }

    @media all and (max-width: $screen-sm) {
      .sm-full {
        width: 100% !important;
        margin-top: 15px;
      }
    }

    @media all and (max-width: $screen-xs) {
      .xs-full {
        width: 100% !important;
        margin-top: 15px;
      }
    }
  }
}

ul {
  list-style: disc !important;

  li:before {
    content: none !important;
  }
}

.admiral-secondary-btn {
  &.btn-default {
    padding: 6px 12px !important;
    background-color: $admiral-button-secondary !important;
    color: $color4 !important;
    border-color: $admiral-button-secondary !important;
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.admiral-blue-btn {
  &.btn-default {
    padding: 6px 12px !important;
    background-color: $blue-section-bg !important;
    color: $admiral-button-primary !important;
    border-color: $admiral-button-primary !important;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &.medium {
    width: 130px
  }
}

.admiral-red-btn {
  &.btn-default {
    padding: 6px 12px !important;
    background-color: $color6 !important;
    color: $color4 !important;
    border-color: $color6 !important;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &.medium {
    width: 130px
  }
}

.error-message {
  border: 1px solid $input-error-border-color;
  background-color: $input-error-border-color;
  padding: 10px;
  color: #FFFFFF;
}
