@import "../../../../styles/config/variables";

.medical-decline {
  background: #f0f8ff;
  text-align: left;

  p {
    font-weight: normal;
  }

  .title {
    text-decoration: underline;
    text-align: center;
    padding-top: 10px;
  }
}

.medical-threshold {
  padding-bottom: 10px;

  .content {
    padding: 20px;
  }

  p {
    font-weight: normal;
  }

  .title {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 2rem;
  }
}


.call-center {
  background: #f0f8ff;
  text-align: center;
  padding: 20px;

  &.no-center {
    text-align: left;
  }

  .title {
    padding-top: 10px;
    font-weight: bold;
  }
}

.medical-directory-link {
    a {
    @media all and (max-width: $screen-xs) {
      font-size: 1.3rem;
    }
  }
}
