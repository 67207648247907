@import '../../../../../styles/config/variables';

.portal-area-top-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  text-align: right;
  background-color: $color-primary;
  color: #FFF;
  padding: 10px 20px 5px 20px;

  .container {
    @media all and (max-width: $screen-sm) {
      margin-right: 15px;
      margin-left: 15px;
    }

    .portal-area-header-flex-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .portal-area-customer {
    text-align: left;
    @media all and (max-width: $screen-sm) {
      padding-left: 0;
      padding-right: 0;
    }

    @media all and (max-width: 368px) {
      font-size: 1rem;
    }
  }

  .pointer {
    @media all and (max-width: $screen-sm) {
      padding-right: 0;
      padding-left: 0;
    }

    @media all and (max-width: 368px) {
      font-size: 1rem;
    }
  }

  &__icon {
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
  }


  a {
    color: #FFF;

    &:hover {
      color: #FFF;
    }
  }

}
