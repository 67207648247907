@import '../../styles/config/variables';

/*------------
Internal Page
------------*/

.hero-container {
  position: relative;
  background: url("./img/travel-single-trip-big.jpg") no-repeat;
  background-size: cover;
  border-radius: $radius-small;
  padding: 60px 15px;
  height: 246px;
  margin-bottom: 20px;
  margin-top: 15px;
  color: $hero-color;

  h1 {
    font-weight: bold;
    font-size: 3em;
    color: $hero-color;
  }

  p {
    font-size: 1.5em;
  }

  //@media all and (min-width: $screen-sm) {
  //  background-position: center -240px;
  //}
  //
  @media all and (min-width: $screen-md) {
    background-position: center -40px;
  }

  @media all and (min-width: $screen-lg) {
    background-position: center -120px;
  }
}

/*------------
Quote journey Containers
------------*/

.quote-content-container {
  border-radius: $radius-small;
  background: $content-bg;
  position: relative;
  //padding-top: 30px;
  color: $section-color;
  font-size: 1.2em;

  ul {
    list-style-type: none;
    padding: 5px;

    li:before {
      content: "\F00C";
      font-family: "FontAwesome";
      font-size: 1.5em;
      color: $li-icon-color;
      margin: 5px;
    }
  }

  ul.declaration {
    list-style-type: none;
    padding: 5px 5px 5px 40px;


    li.declaration-list {
      position: relative;
      margin: 20px 0;
    }

    li.declaration-list:before {
      content: "\F00C";
      font-family: "FontAwesome";
      font-size: 24px;
      color: $li-icon-color;
      margin: 5px;
      position: absolute;
      left: -35px;
      top: -12px;
    }

  }

  ul.declaration-green {
    list-style-type: none;
    padding: 5px 5px 5px 40px;


    li.declaration-list {
      position: relative;
      margin: 20px 0;
    }

    li.declaration-list:before {
      content: "\F00C";
      font-family: "FontAwesome";
      font-size: 24px;
      color: $color16;
      margin: 5px;
      position: absolute;
      left: -35px;
      top: -12px;
    }

  }

  .medical-questions-container ul {
    list-style-type: disc;
    padding: 0;
    margin-left: 40px;

    li:before {
      content: none;
    }
  }

  p {
    font-weight: bold;
  }

  p.normal {
    font-weight: normal;
  }

  h4, h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: $alternative-headings-color;
  }

  .steps-container {
    max-width: $max-width-page-container;
    margin: auto;
  }
}

.p-out-of-section {
  padding: 0 10px;
  color: $color1;
}

.group-type-description {
  font-size: 0.8em;
}

.remove-margins-from-children {
  > * {
    margin: 0px !important;
  }
}

/*------------
Your Quote summary
------------*/

.quote-info {
  text-align: right;
  padding-bottom: 15px;

  &__final-price {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: normal !important;
  }

  ul {
    font-weight: bold;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0 0 10px 0;

      &:before {
        content: '';
        position: absolute;
      }
    }
  }
}

/*------------
Voucher code area
------------*/

.voucher-code {

  font-size: 0.9em;

  ul {
    list-style-type: none;
    padding: 0 0;
    font-size: 0.9em;


    li.list {
      position: relative;
      margin-left: 18px;
    }

    li.list:before {
      content: "\F00C";
      font-family: "FontAwesome";
      font-size: 1em;
      color: $li-icon-color;
      margin: 5px;
      position: absolute;
      left: -23px;
      top: -5px;
    }
  }
}

.voucher-code-input {
  @media all and (max-width: $screen-sm) {
    margin-bottom: 15px;
  }
}

/*------------
Error block
------------*/
.error-block-container {
  background: $error-bg;
  padding: 15px;
  text-align: center;
  z-index: 2;
  margin-bottom: 30px;

  &:before {
    content: '';
    background: $error-bg;
    width: 100%;
    height: 100%;
    top: -15px;
    left: 0;
    z-index: 0;
  }

  h1 {
    color: $error-block-color;
    font-weight: bolder;
  }

  p {
    color: $error-block-color;
    font-weight: normal;
  }
}

/*------------
RESIZE React DatePicker
------------*/

.react-datepicker-wrapper {
  //border: 3px solid orange;
  width: 100% !important;
  position: relative;
}

.react-datepicker-popper {
  left: -186px !important;
  z-index: 1000;
}

.start-date-input, .end-date-input {
  .react-datepicker-popper {
    left: unset !important;
    z-index: 1000;
  }
}

.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker {
  font-size: 1.6rem !important;
  width: 240px;

  .react-datepicker__triangle {
    margin-left: 120px !important;
    left: 75px;
  }

  .react-datepicker__month-container {
    width: 238px !important;

    .react-datepicker__day {
      padding: 0;
    }

    .react-datepicker__day--selected {
      background-color: $color8;
    }
  }
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
  color: $color4;
}

.react-datepicker__day-names {
  div {
    color: $color4;
  }
}

.react-datepicker__header {
  padding-top: 6px !important;
  background: $color1;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.3rem;
}

.react-datepicker__day--keyboard-selected {
  background-color: #FFFFFF;
  border: solid 1px $color8;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  line-height: 3rem;
}

.react-datepicker__day:not(.react-datepicker__day--disabled, .react-datepicker__day--selected) {
  color: #4f4f4fbf;
}

/*------------
INFO BUTTONS - renderDefinitions
------------*/

.info-btn {
  margin-left: 5px;
  //color: $important-message-color !important;
  color: $color8;
  font-size: 22px;
  vertical-align: middle;

  @media all and (max-width: $screen-xxs) {
    font-size: 18px;

    &.no-margin {
      margin-left: 0;
    }
  }

}

/*------------
SAVE-QUOTE-BTN
------------*/

.save-quote-btn {
  float: left;

  @media all and (max-width: $screen-xs) {
    float: right;
    padding: 15px 0;
    margin-bottom: 15px;
    width: 193px;
  }
}

.screen-button {
  min-width: 90px;
  @media all and (max-width: $screen-xs) {
    margin: 15px;
  }
}

/*-----------
LAYOUT-HACK
 */
@media all and (max-width: $screen-xs) {
  .full-width-xs {
    width: 100% !important;
  }

  .half-width-xs {
    width: 49% !important;
  }
}

@media all and (max-width: $screen-smx) {
  .dropdown-text {
    font-size: 10px;
  }
}

.covid-disclaimer-container {
  margin-bottom: 25px;

  .dropdown-box {
    display: flex;
    padding: 10px 10px 5px 10px;
    background-color: $color1;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;

    .dropdown-box-text {
      flex-basis: 90%;
    }

    .dropdown-box-icon {
      flex-basis: 10%;
      text-align: right;

      .svg {
        font-size: 25px;
      }
    }
  }

  .covid-disclaimer-body {
    padding: 15px;

    .sub-heading {
      color: #41A5F5;
    }
  }
}

.information-panel-container {

  .dropdown-box {
    display: flex;
    padding: 10px;
    background-color: $color1;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;

    .dropdown-box-text {
      flex-basis: 70%;
      font-size: 1.4rem;
    }

    .dropdown-box-icon {
      flex-basis: 30%;
      text-align: right;
      font-size: 1.4rem;

      .svg {
        font-size: 25px;
      }
    }
  }

  .information-panel-body {
    font-size: 16.8px;
    padding: 15px;
    background-color: $color26;

    .sub-heading {
      color: #41A5F5;
    }
  }
}


.admiral-white-btn {
  &.btn-default {
    padding: 6px 12px !important;
    background-color: $color4 !important;
    color: $admiral-button-primary !important;
    border-color: $admiral-button-primary !important;
    font-size: 1.6rem;
    font-weight: 400;
  }
}
