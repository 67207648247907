@import "../../../../styles/config/variables";

.renewals-details-info {
  background-color: #edf7ff;
  border-left: solid 4px $color1;
  padding: 10px 35px;

  svg {
    padding-top: 4px;
  }

  .info-icon {
    position: absolute;
    left: 25px;
  }

}

.radio-text {
  padding-left: 15px;
}

.renewal-selection {
  background-color: #edf7ff !important;
}

.has-error-red {
  font-size: 1.5rem;
  font-weight: bold;

  svg {
    width: 1.3em;
    height: 1.3em;
  }
}
