@import '../../../../styles/config/variables';

footer {
  background: $footer-bg;
  color: $footer-color;
  text-align: center;
  font-size: 0.8em;
  padding: 20px 0 0 0;
  position: fixed;
  bottom: 0;
  width: 100%;

  a {
    color: $cookieAndPrivacyLinkColor;
    text-decoration: none;
    margin-right: 5px;

    &:hover {
      text-decoration: none;
      color: $cookieAndPrivacyLinkColor;
    }
  }

  @media all and (max-width: $screen-xs) {
    position: relative;
  }
}
