@import ".././../../../styles/config/variables";

.text-me-details-field {
  background: $payment-text-me-bg;
  color: $payment-text-me-color;
  border-radius: $radius-small;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 30px;

  .control-label {
    font-weight: normal;
  }

  svg {
    font-size: 26px;
    padding-left: 1px;
    color: $payment-text-me-icon-color;
    float: right;
    right: 0;
    top: 5px;
    position: absolute;
  }

  .text-me-radio {
    float: right;
  }
}

.payments-modal {
  width: 100%;
  min-height: 655px;
  border: 0;

  @media all and (min-width: $screen-md) {
    min-height: 600px;
  }
}

.payment-logos {
  margin-top: -15px;
  margin-bottom: 15px;

  @media all and (max-width: $screen-xs) {
    text-align: center;
  }

  img {
    width: 100%;

    @media all and (max-width: $screen-xs) {
      width: 60%;
    }
  }
}

.permissions-box {
  text-align: left;
  font-size: 0.8em;

  .title {
    font-size: 1.4em;
    margin-bottom: 30px !important;
  }

  p {
    margin-top: 15px;
    font-size: 1em;
  }

  .icon {
    font-size: 2.0em;
  }

  .environment-text {
    font-size: 0.8em;
    font-weight: 500;
  }

  .radio-group {

    padding: 0;

    .radio-button {
      width: 10%;
    }

    .radio-text {
      width: 90%;
      margin: auto 0 auto 0;
    }

    .option {
      display: inline-flex;
      width: 100%;
      background-color: #FFF;
      padding: 15px;
      border: 1px solid #D1C7BD;
      font-size: 1.2em;
    }
  }

  .select-option {
    font-size: 1.3em;
  }
}

@media all and (max-width: $screen-xs) {
  .benefits {
    font-size: 13px;
  }
}

.purchase-button {
  width: 193px;
}

.radio-group {
  .option {
    border-radius: 3px;
  }
}

.payment-total-price {

  .total-pay-text {
    b {
      @media all and (max-width: 400px) {
        font-size: 0.8em;
      }
    }
  }

  span {
    font-size: 1.5em;
    color: $color1;
  }
}

.payment-price-info {
  font-style: italic;
  color: $color1;
  font-size: 1.2rem;
}

.duplicate-warning {
  color: $color1;
  background: $color26;
  font-weight: bold;
  padding: 10px;
}

.link-element {
  cursor: pointer;
  text-decoration: underline;
}
