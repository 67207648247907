@import '../../../../styles/config/variables';

.banner {

  img {
    width: 100%;
  }

  @media all and (min-width: $screen-sm) {
    &-mobile {
      display: none;
    }
  }

  @media all and (max-width: $screen-sm - 1) {
    &-desktop {
      display: none;
    }
  }
}

