@import '../../styles/config/variables';


.customer-area-container {
  border-radius: $radius-small;
  background: $content-bg;
  position: relative;
  color: $section-color;
  font-size: 1.2em;

  h4, h3, h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    color: $color2;
  }

  h2 {
    font-size: 26px;
  }

  // Note: uncomment this if you want to limit the page size back to 708px / $max-width-page-container
  //.page-container {
  //  max-width: $max-width-page-container;
  //  margin: auto;
  //}

}

.blue-section {
  border: 1px solid $blue-section-border-color;
  border-radius: $radius-small;
  background: $blue-section-bg;
  margin-bottom: 15px;
  padding: 15px;
  color: $blue-section-color;

  .form-control {
    border: 1px solid $blue-section-border-color;
  }

  strong {
    color: $headings-color;
  }

  h4, h3 {
    color: $headings-color !important;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.blue-section-group {
  border: 1px solid $blue-section-border-color;
  border-radius: $radius-small;
  margin-bottom: 15px;
  overflow: hidden;

  .blue-section {
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    border-top: 1px solid $blue-section-border-color;

    &:first-child {
      border-top: none;
    }
  }
}

.customer-section-title {
  color: $headings-color;

  h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 0px -15px 25px -15px;
    padding: 15px;
    background-color: #2350A0;
    color: #FFF;
  }

  h3 {
    font-weight: bold;
    margin: 0px -15px 25px -15px;
    padding: 15px;
    background-color: #2350A0;
    color: #FFF;
  }

  .login-failure {
    color: $color6;
    font-size: 0.9em;
  }
}

.accessibility-support-container {

  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding:15px;
  margin: 30px auto 30px auto;
  max-width: $max-width-page-container;

  h3 {
    font-weight: bold;
    margin: 0px -15px 25px -15px;
    padding: 15px;
    background-color: #2350A0;
    color: #FFF;
  }

}

.reset-message {
  text-align: center;
}

.redirect {

  svg {
    font-size: 2em;
    margin: 15px auto;
  }
}

.underline {
  text-decoration: underline;
}

.button-bar {
  margin-top: 10px;

  &__yes {
    margin-right: 20px;
  }

  &__yes, &__no {
    font-size: 16px;
  }

  &__no {
    background-color: #ececec;
  }
}

.clickable-text {
  font-weight: bold;
  color: $headings-color;
  cursor: pointer;
}

.cancel-loader {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.expand-icon {
  color: $screening-more-info-text-color;
}

iframe {
  border: 0;
}

.medium-width {
  font-size: 16px;
  width: 180px;

  @media all and (max-width: $screen-xs) {
    font-size: 14px;
  }
}

.full-width {
  font-size: 16px;

  @media all and (max-width: $screen-xs) {
    font-size: 14px;
  }
}

.blue-divider {
  border: 0.5px solid $color9;
}

.faqs {
  a {
    color: $link-color1 !important;
  }
}

.radio-questions {

  .answer {

    input {
      display: block;
      opacity: 0;
    }

    .yes {
      border-top-left-radius: $radius-small;
      border-bottom-left-radius: $radius-small;
    }

    .no {
      border-top-right-radius: $radius-small;
      border-bottom-right-radius: $radius-small;
    }
  }

  label {
    padding-left: 0;
    width: 100%;
    font-weight: bold;

    .label-btn {
      padding: 10px 15px;
      text-align: center;
      font-size: .8em;
      text-transform: capitalize;
      cursor: pointer;
      font-weight: bold;
      color: $input-button-color;
      border: 1px solid $input-button-border-color;
      background: $white-btn-gradient;
      transition: $transition;

      //&:hover {
      //  box-shadow: $btn-inset-shadow;
      //}
    }

    input:disabled + .label-btn {
      opacity: .6 !important;
      cursor: wait;

      &:hover {
        box-shadow: none;
      }
    }

    input:checked ~ .label-btn {
      &.yes {
        opacity: 1;
        background: $light-blue-btn-gradient;
        color: $input-button-selected-color;
        border: $yellow-btn-border;
      }

    }

    input:checked ~ .label-btn {
      &.no {
        position: relative;
        opacity: 1;
        background: $light-blue-btn-gradient;
        color: $input-button-selected-color;
        border: $yellow-btn-border;
        right: 1px;
      }

    }

    input:not(:checked) ~ .label-btn {
      &.no {
        border-left: none;
      }
    }

  }

  input:focus + .label-btn {
    box-shadow: 0 0 3px $input-button-color;
  }
}
