@import '../../../../styles/config/variables';

.additional-option {
  background: none !important;
  padding: 5px 0 !important;

  &:first-of-type {
    padding-top: 0 !important;
  }
}

.trip-details-addition {
  padding-top: 30px;

  @media all and (max-width: $screen-sm) {
    padding-top: 0;
  }
}

.question-text {
  padding-top: 10px;

  .title {
    font-weight: normal;
    color: $color1;
    font-size: 16px;
  }

  .additional-cover-icon-desktop {
    margin: 0 5px 0 0;
    width: 60px;
    height: 80px;
    float: left;
    padding-top: 10px;
  }

  .additional-cover-icon-desktop-ws-cc {
    margin: 0 5px 0 0;
    width: 60px;
    height: 80px;
    float: left;
    padding-top: 30px;
  }

  .additional-cover-icon-mobile {
    margin: 0 10px 10px 0;
    width: 60px;
    height: 60px;
  }

  .selected-no-text {
    color: $color7;
  }
}

.send-documents-box {
  text-align: left;
  font-size: 0.8em;
  padding-bottom: 30px;

  &.no-padding {
    padding-bottom: 0;
  }

  .title {
    font-size: 1.4em;
    margin-bottom: 30px !important;
    margin-top: 20px;
  }

  p {
    margin-top: 15px;
    font-size: 1em;
  }

  .icon {
    font-size: 2.0em;
  }

  .environment-text {
    font-size: 0.8em;
    font-weight: 500;
  }

  .radio-group {

    padding: 0;

    .radio-button {
      width: 10%;
    }

    .radio-text {
      width: 90%;
      margin: auto 0 auto 0;
    }

    .option {
      display: inline-flex;
      width: 100%;
      background-color: #FFF;
      padding: 15px;
      border-top: 1px solid $color22 !important;
      font-size: 1.2em;

      &.no-top-border {
        border-top: 0 !important;
      }

      &.no-top-padding {
        padding-top: 0;
      }

      &.no-bottom-padding {
        padding-bottom: 0;
      }
    }
  }

  .select-option {
    font-size: 1.3em;
  }
}


.options-section-group {
  margin: 30px 0;

  .options-section {
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    padding: 15px 0;
    border-top: 1px solid $section-border-color;
    animation: fadeIn .5s ease;
    opacity: 1;

    p {
      font-weight: normal;
    }

    .covered-btn {
      background: $yellow-btn-gradient;
      color: $input-button-selected-color;
      border: $yellow-btn-border;
      padding: 10px 15px;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: .8em;
      text-transform: uppercase;
      cursor: not-allowed;
      border-radius: $radius-small;
      font-weight: bold;
    }

    .option-question-container {
      @media all and (max-width: $screen-sm) {
        margin-left: 25px;
        margin-right: 25px;
      }
    }

    .option-question, .checkbox {

      label {
        padding-left: 0;
        width: 100%;
        font-weight: bold;

        .option-btn {
          padding: 10px 15px;
          width: 100%;
          height: 100%;
          text-align: center;
          font-size: .8em;
          text-transform: uppercase;
          cursor: pointer;
          border-radius: $radius-small;
          color: $input-button-color;
          border: 1px solid $white-btn-border !important;
          background: $white-btn-gradient;
          font-weight: bold;
          transition: $transition;
          display: flex;
          align-content: space-between;

          .option-content {
            background: none;
            position: relative;
            padding-top: 5px;
            float: left;
            border: none;
            display: flex;
            margin: auto;
            width: auto;

            span {
              text-align: left;
              margin: 0 2px;
            }
          }

          &:hover {
            box-shadow: $btn-inset-shadow;
          }

          &:after {
            display: none;
          }

          .add {
            display: block;
          }

          .remove {
            display: none;
          }
        }

        input:checked + .option-btn {
          opacity: 1;
          background: $light-blue-btn-gradient;
          color: $input-button-selected-color;
          border: $light-blue-btn-border;

          &:hover {
            box-shadow: none;
          }

          .add {
            display: none;
          }

          .remove {
            display: block;
          }
        }

        input:disabled + .option-btn {
          opacity: .6;
          cursor: wait;

          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sticky-container {
  height: 100% !important;
  z-index: 1000 !important;;
  overflow: hidden !important;;
  position: absolute !important;;
  width: 100% !important;;
  pointer-events: none !important;;
}

.choose-from-a-range-title {
  padding-top: 120px;

  @media all and (max-width: 370px) {
    padding-top: 150px;
  }
}
