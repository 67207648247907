@import '../../styles/config/variables';

.expired-card-banner {
  background: #e05f5f;
  padding: 10px 0;
  text-align: center;
  color: $color4;
  cursor: pointer;
  font-size: 19px;

  @media all and (max-width: $screen-xs) {
    font-size: 16px;
  }
}
