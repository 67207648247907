@import ".././../../../styles/config/variables";

.traveller-medical {
  background: $travellers-bg;
  padding: 0;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.important {
  ul {
    li {
      list-style-type: circle;
      list-style-position: outside;

      &:before {
        content: none !important;
      }
    }
  }
}

.medical-question {

  //p {
  //  font-size: 2.2rem;
  //}

  .col-xs-6 {
    padding: 0;
  }

  @media all and (max-width: 767px) {
    .medical-btn.yes {
      margin-left: 40px;
    }
    .medical-btn.no {
      margin-right: 40px;
    }
  }

  .answer {

    input {
      display: block;
      opacity: 0;
    }

    .yes {
      border-top-left-radius: $radius-small;
      border-bottom-left-radius: $radius-small;
    }

    .no {
      border-top-right-radius: $radius-small;
      border-bottom-right-radius: $radius-small;
    }
  }

  .answer-number {

    input {
      display: block;
    }
  }

  label {
    padding-left: 0;
    width: 100%;
    font-weight: bold;

    .medical-btn {
      padding: 10px 15px;
      text-align: center;
      font-size: .8em;
      text-transform: capitalize;
      cursor: pointer;
      font-weight: bold;
      color: $input-button-color;
      border: 1px solid $input-button-border-color;
      background: $white-btn-gradient;
      transition: $transition;

      //&:hover {
      //  box-shadow: $btn-inset-shadow;
      //}
    }

    input:disabled + .medical-btn {
      opacity: .6 !important;
      cursor: wait;

      &:hover {
        box-shadow: none;
      }
    }

    input:checked ~ .medical-btn {
      &.yes {
        opacity: 1;
        background: $light-blue-btn-gradient;
        color: $input-button-selected-color;
        border: $yellow-btn-border;
      }

    }

    input:checked ~ .medical-btn {
      &.no {
        position: relative;
        opacity: 1;
        background: $light-blue-btn-gradient;
        color: $input-button-selected-color;
        border: $yellow-btn-border;
        right: 1px;
      }

    }

    input:not(:checked) ~ .medical-btn {
      &.no {
        border-left: none;
      }
    }

  }

  input:focus + .medical-btn {
    box-shadow: 0 0 3px $input-button-color;
  }
}

.medical-section-group {
  margin-bottom: 15px;

  .medical-section {
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    padding: 15px 0;
    border-top: 1px solid $divider-color;

    p {
      font-weight: normal;
    }

    &:first-child {
      border-top: none;
    }
  }

  .option-section {
    border-radius: 0;
    margin-bottom: 0;
    border: none;

    p {
      font-weight: normal;
    }

    .option-subtext {
      margin-top: 5px;
      font-size: 14px;
      @media all and (max-width: $screen-xs) {
        text-align: center;
        margin: 10px 0 10px 0;
      }
    }

    .option-subtext-display {
      padding: 15px 0 0 80px;

      @media all and (max-width: $screen-xs) {
        padding: 15px 15px 0 15px;
        font-size: 12px;
      }
    }

  }

  hr {
    border-top: 1px solid $divider-color;
  }
}

.has-error {
  .answer {
    .medical-btn {
      border: 1px solid $input-error-border-color;
      color: $input-error-border-color;
    }

    .yes {
      border-right: 1px solid $input-error-border-color;
    }
  }
}

.unable-box {
  text-align: center;
}

// screening

.screening-session-healix {
  border: 1px solid $section-border-color;
  border-radius: $radius-small;
  background: $section-bg;

  .traveller-name {
    color: $headings-color;
    padding: 15px;
    border-bottom: 1px solid $section-border-color;
  }

  .screening-body {
    margin-bottom: 15px;
    padding: 15px;

    .condition {
      border: 1px solid $section-border-color;
      border-radius: $radius-small;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      span {
        color: $headings-color;
      }
    }

    .declaration-actions {
      border-top: 1px solid $section-border-color;
      padding-top: 15px;
      margin-top: 15px;
    }
  }
}


.condition-questions {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .condition-label {
    color: $headings-color;
    margin-top: 0;
    border-bottom: 1px solid $color12;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  label {
    padding-bottom: 10px;
  }

  .text-info {
    font-size: 0.9em !important;
    margin: -10px 0 20px;
    color: $screening-more-info-text-color;
  }

  .text-danger {
    font-size: 0.9em !important;
    margin: -10px 0 20px;
    color: $screening-warning-text-color;
  }

  .screening-radio-question {
    margin-bottom: 15px;

    .radio, .checkbox, .btn-quote-option {

      input {
        opacity: 0;
      }

      label {
        padding: 0 0 2px 0;
        font-weight: bold;

        div {
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: $white-bg;
          margin-right: 10px;
          margin-bottom: -3px;
          border: 1px solid $input-button-selected-bg;
          position: relative;
          z-index: 1;

          &:after {
            opacity: 0;
            //content: "\F00C";
            //font-family: "FontAwesome";
            content: '';
            background: url("../../img/checkbox-tick-white.svg") 4px 0 no-repeat, $btn-primary-gradient;
            background-size: 70% 100%;
            font-size: 1.2em;
            color: white;
            text-align: center;
            padding: 4px 2px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            position: absolute;
            left: -1px;
            top: -1px;
            transition: 0.2s ease;
            line-height: 17px;
          }
        }

        input:checked + div::after {
          opacity: 1;
        }
      }
    }
  }
}

.declared-conditions {
  margin: 25px 0;

  .condition {
    background: rgba($color5, .3);
    padding: 15px 0;
    margin: 0 0 15px 0;

    .btn {
      margin-left: 10px;
    }

    @media all and (max-width: 634px) {
      text-align: center;
    }
  }

  .primary-condition {
    margin: 0;
  }

  .linked-condition {
    padding-right: 5px;
  }

  .actions {
    button {
      float: right;
      margin-left: 10px;
    }
  }

  .reverse-buttons {
    display: flex;
    flex-wrap: wrap-reverse;
    max-width: 160px;
    float: right;
    align-content: flex-end;

    .declared-conditions-button {
      width: 70px;
    }

    @media all and (max-width: 634px) {
      max-width: 95px;
    }
  }

}

.toc {
  text-align: left;
  padding-left: 15px;
  padding-top: 15px;
}

.medical-declaration-text-sub {
  color: #41A5F5;
}
