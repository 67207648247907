@import '../styles/config/variables';

.admiral-table {
  padding: 0 !important;
  width: 100%;
  overflow-x: auto;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);

  a {

    color: #000;

    &:hover {
      text-decoration: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: #FFF;
    margin: 0;
    font-weight: normal;
    font-size: 16.8px;
  }

  table {
    margin: 0;
    border: none !important;
    border-radius: 5px;

    thead {
      tr {
        background-color: $color-primary;
        color: #FFF;
        font-weight: normal;
      }

      &:first-child > tr:first-child > th {
        border-bottom: 0;
        border-top: 0;
      }

      th {
        padding: 15px;
        font-weight: normal;
        font-size: 16.8px;
      }
    }

    tbody {
      tr {
        background: none;
        border-bottom: 1px solid $color-quaternary;

        td {
          padding: 15px;
          border: none !important;
        }
      }
    }

    button {
      width: 100%;
    }

  }

  &.premiums {
    table {
      tr {
        td {
          text-align: center;
        }
      }
    }
  }

  &--alternative {
    text-align: center;

    table {

      tr {
        background-color: #F5F7FC;
        border-bottom: none;
      }
    }


    h3 {
      text-align: center;
    }
  }

  &__discount-value {
    text-decoration: line-through;
    font-size: 14px;
  }

  @media all and (max-width: $screen-lg) {
    th[data-lg-hide="hide"] {
      display: none;
    }

    tbody {
      tr {
        td[data-lg-hide="hide"] {
          display: none;
        }
      }
    }
  }

  @media all and (max-width: $screen-md) {
    th[data-md-hide="hide"] {
      display: none;
    }

    tbody {
      tr {
        td[data-md-hide="hide"] {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    th[data-mds-hide="hide"] {
      display: none;
    }

    tr {
      td[data-mds-hide="hide"] {
        display: none;
      }

      .render {
        display: block !important;
      }
    }
  }

  @media all and (max-width: $screen-sm) {

    &:not(.no-wrap) {

      thead {
        border: none !important;

        tr {
          border: none !important;
        }
      }

      th {
        display: none;
        border: none !important;
      }

      th[data-sm-show="show"] {
        display: block;
      }

      tbody {
        tr {
          display: flex;
          flex-flow: row wrap;
          width: auto;

          td {
            border: none !important;
            padding: 5px 15px 5px 15px !important;
            width: 100%;

            &[data-md-hide="hide"] {
              display: block;
            }

            &[data-sm-show="show"] {
              display: block;
            }

            &.t-bold {
              font-weight: bold;
            }

            &.traveller-email {
              max-width: 260px;
              word-wrap: break-word;
            }

            button {
              float: left;
              width: 48%;
              margin-bottom: 10px;
            }
          }

          &.documents {
            flex-flow: unset;

            td {
              padding: 15px 15px 15px 15px !important;
            }

            a {
              float: right;
            }
          }
        }
      }
    }
  }
}

.cancellation-table-wrapper {
  background-color: $color26;
  padding: 15px;

  .cancellation-table {
    background-color: #FFFFFF;
    border: 1px solid black;

    td {
      background-color: #FFFFFF;
      border: 1px solid black;
      text-align: center;
      color: black;
      padding: 5px;
      font-size: 1.6rem;
    }
  }
}
