@import '../../styles/config/variables';

.upsell-modal {

  @media (min-width: 768px) {
    .modal-dialog {
      width: 700px;
      margin: 30px auto;
    }
  }

  .marketing-upsell-modal-body {
    display: flex;

    .upsell-header-image {
      flex-basis: 30%;
      padding-top: 10px;
      img {
        position: relative;
        width: 100%;
        //transform: scaleX(-1);
        max-height: 350px;
      }
    }


    @media (max-width: $screen-smx) {
      .upsell-header-image {
        display: none;
      }
    }

    .header-text-wrapper {
      flex-basis: 70%;
      font-weight: $admiral-font-weight-bold;
      line-height: 1.3;
      padding: 0 10px;

      @media (max-width: $screen-smx) {
        flex-basis: 100%;
      }

      .header-title {
        text-align: center;
        font-size: 1.6em;
      }

      .header-subtitle {
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 10px;
      }

      .upsell-benefits-container {
        margin-left: 60px;

        @media (max-width: $screen-xs) {
          margin-left: 40px;
        }

        .upsell-benefits {
          padding: 10px 0;
          font-size: 0.8em;
        }
      }

      .modal-footer-buttons {
        padding: 15px 0 15px 0;
        display: flex;
        flex-wrap: wrap;

        .accept {
          flex: 1;
          margin: 5px;
        }

        .cancel {
          flex: 1;
          margin: 5px;
          opacity: 60%;
        }
      }
    }
  }
}

.upsell-discount {
  text-align: center;
  color: #337ab7;

  .upsell-discount-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

.info-dropdown {
  padding-bottom: 5px;
  margin-left: 55px;
}
