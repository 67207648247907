@import ".././../../../../../styles/config/variables";

.title-your-renewal {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: $color1
}

.section-group-content:not(.no-padding) {
  padding: 20px 20px 0 20px;
}

.notes-your-renewal {
  background-color: #FFF;
  padding: 15px;
  margin: 0 5px 0 5px;
  border: 1px solid #D1C7BD;
  font-size: 15px;

  p {
    font-weight: normal;
  }
}

.selection-your-renewal {
  margin-right: -10px;
  margin-left: -10px;
}

.radio-group {

  padding: 0;

  .radio-button {
    width: 10%;

    @media all and (max-width: $screen-smx) {
      width: 20% !important;
      font-size: 0.8em;
    }

    .sessioncamhidetext, .radio {
      &.disabled {
        label > div {
          border: 1px solid #8A8A8A98 !important;
        }
      }
    }

    @media all and (max-width: $screen-xs) {
      width: 100% !important;

      .radio {
        text-align: center;

        label {
          padding-left: 10px;
        }
      }
    }

    @media all and (max-width: $screen-xxs) {
      font-size: 0.6em;
    }
  }

  .radio-text {
    width: 90%;
    margin: auto 0 auto 0;

    @media all and (max-width: $screen-smx) {
      width: 80% !important;
      font-size: 0.8em;
    }

    @media all and (max-width: $screen-xs) {
      width: 100% !important;
      text-align: center;
      height: 20px;
    }
  }

  .dont-save-text {
    @media all and (max-width: $screen-smx) {
      font-size: 0.8em;
    }
  }
}
