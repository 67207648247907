@import '../../../../../../styles/config/variables';

.trash-btn {
  position: relative;
  z-index: 2;
  float: right;
  margin-top: -30px;
  @media all and (max-width: $screen-sm) {
  }
}

.margin-bottom {
  margin-bottom: 15px;
}
