.input-with-icon {
  position: relative;

  .input-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    opacity: 0.5;
  }
}
