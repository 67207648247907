@import '../../../../../../styles/config/variables';


.info-banner {
  width: 100%;
  padding: 8px;
  background-color: $color-primary;
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;

  &__icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 50px;
      width: 50px;
      fill: $color4 !important;
    }
  }
  &__title {
    display: block;
    margin-bottom: 3px;
    font-size: 20px;
    font-weight: bold;
  }

  &__text {
    font-size: 14px;
  }

  &__info {
    border-left: 2px solid #FFF;
    padding-left: 10px;
  }
}
