@import "../../../../../../styles/config/variables";

.wizard {
  list-style: none;
  overflow: hidden;
  margin-top: 15px;
  padding: 0;
  border-radius: $radius-small;
  z-index: 5;
  position: relative;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
  }

  .disabled {
    &:first-child {
      a {
        border-radius: $radius-small 0 0 $radius-small;
        border-left: 1px solid $headings-color;
      }
    }

    &:last-child {
      a {
        border-radius: 0 $radius-small $radius-small 0;
        border-right: 1px solid $headings-color;
        border-left: none;
      }
    }
  }

  li {
    padding: 0;
    display: flex;
    margin: 0;
    flex: 1;

    &:first-child {
      a {
        border-radius: $radius-small 0 0 $radius-small;
        border-left: 1px solid $headings-color;
      }
    }

    &:last-child {
      a {
        border-radius: 0 $radius-small $radius-small 0;
        border-right: 1px solid $headings-color;

        &:after, &:before {
          display: none;
        }
      }
    }

    &:before {
      display: none;
    }

    a {
      color: $wizard-color;
      text-decoration: none;
      background: $wizard-bg;
      border-top: 1px solid $wizard-color;
      border-bottom: 1px solid $wizard-color;
      position: relative;
      height: 34px;
      padding-left: 15px;
      padding-top: 7px;
      text-align: center;
      flex: 1;
      align-items: center;
      transition: .3s ease;

      &:after {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 15px solid $white-bg;
        position: absolute;
        top: 100%;
        margin-top: -32px;
        left: 100%;
        z-index: 1;
        transition: .3s ease;

        @media all and(max-width: $screen-sm) {
          margin-left: -1px;
        }
      }

      &:before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-left: 17px solid $wizard-color;
        position: absolute;
        top: 100%;
        margin-top: -34px;
        left: 100%;
        z-index: 1;
      }

      p {

        @media all and(max-width: $screen-sm) {
          font-size: 1rem;
        }

        @media all and(max-width: $screen-smx) {
          display: none;
        }
      }

      span {
        @media all and(max-width: $screen-smx) {
          display: none;
        }
      }

      .number {
        display: none;
      }

      span {
        margin-right: 5px;
      }
    }

    &.active a {
      background: $wizard-color;
      color: $white-bg;

      &:after {
        border-left: 16px solid $wizard-color;
      }

      span {
        color: $white-bg;
      }
    }

    &.disabled a {
      color: $wizard-color;
      cursor: default;
      background: $white-bg;
      border-top: 1px solid $wizard-disable-color;
      border-bottom: 1px solid $wizard-disable-color;
      border-right: none;

      &:after {
        border-left: 15px solid $white-bg;
      }

      &:before {
        border-left: 17px solid $wizard-disable-color;
      }

      &:active,
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  @media all and (max-width: $screen-sm) {
    border-right: none;

    li {

      a {
        padding: 7px;

        .number {
          display: block;
        }

      }
    }
  }
}
