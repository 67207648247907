@import '../../../../styles/config/variables';

.schemes-loader {
  position: relative;
  min-height: 200px;

  .loading-icon {
    width: 25px;
    height: 25px;

    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

// new quote

.scheme-choice-section {
  max-width: $max-width-page-container;
  margin: auto;
  text-align: center;

  h1 {
    @media all and (max-width: $screen-xs) {
      font-size: 1.7em;
    }
  }

  .active {
    //border: 5px solid $available-quotes-active-border-color !important;
    outline-offset: -5px;
    outline: 6px solid $available-quotes-active-border-color;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
    transition: $transition;
  }

  .available-quotes {

    .quote-container {
      border-radius: $radius-small;
      -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
      margin: 30px 0 30px 0;

      .quote-header-container {

        //padding-bottom: 70px;
        //
        //@media all and (max-width: 465px) {
        //  padding-bottom: 50px;
        //}
        //
        //@media all and (max-width: 437px) {
        //  padding-bottom: 57px;
        //}

        div:nth-child(1) {
          padding-right: 0;
          padding-left: 0;
        }

        div:nth-child(2) {
          padding-right: 0;
          border-right: 3px solid $color23;
          padding-left: 0;
          border-left: 3px solid $color23;
        }

        div:nth-child(3) {
          padding-left: 0;
          padding-right: 0;
        }

        .quote-header {
          background: $available-quotes-header-bg;
          color: $available-quotes-header-color;
          //border-top-right-radius: $radius-small;
          //border-top-left-radius: $radius-small;
          text-transform: uppercase;
          padding: 15px;
          font-size: 1.4em;
          text-align: center;

          @media all and (max-width: $screen-xs) {
            font-size: 1em;
          }
        }

      }

      .quote-body {
        padding: 0 15px 0 15px;
        //border: 1px solid $available-quotes-border-color;
        border-top: none;
        background: $available-quotes-bg;
        position: relative;

        .quote-value-container {
          background-color: $color4;

          .discount-price-strikethrough {
            font-size: 1.3rem;
            text-decoration: line-through;
          }

          div:nth-child(1) {
            padding-right: 0;
            padding-left: 0;
          }


          div:nth-child(2) {
            padding-right: 0;
            border-right: 3px solid $color23;
            padding-left: 0;
            border-left: 3px solid $color23;
          }

          div:nth-child(3) {
            padding-left: 0;
            padding-right: 0;
          }

          @media all and (max-width: $screen-xs) {
            max-height: 69.59px;
            h1 {
              font-size: 2.5rem;
            }
          }

          .quote-prices {

            background-color: $available-quotes-header-color;

            h1.discount-price {
              margin-top: 10px;
            }

            &.selected {
              background: $available-quotes-header-bg;

              h1 > span {
                color: $available-quotes-header-color !important;
              }

              .discount-price-strikethrough {
                color: $available-quotes-header-color !important;
              }
            }

            @media all and (max-width: 350px) {
              h1 {
                font-size: 2.2rem;
              }
            }
          }
        }

        .discount {
          max-height: 69.59px;

          @media all and (max-width: $screen-xs) {
            max-height: 55px;
          }
        }

        .quote-select-container {
          padding-top: 69px;

          &.discount {
            padding-top: 70px;

            @media all and (max-width: $screen-xs) {
              padding-top: 56px;
            }
          }

          @media all and (max-width: $screen-xs) {
            padding-top: 53px;
          }

          .button-wrapper-0 {
            padding-right: 0;
            padding-left: 0;
          }

          .button-wrapper-1 {
            padding-right: 0;
            border-right: 3px solid $color23;
            padding-left: 0;
            border-left: 3px solid $color23;
          }

          .button-wrapper-2 {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .quote-select-annual-container {
          .button-wrapper-0 {
            padding-right: 0;
            padding-left: 0;
          }

          .button-wrapper-1 {
            padding-right: 0;
            border-right: 3px solid $color23;
            padding-left: 0;
            border-left: 3px solid $color23;
          }

          .button-wrapper-2 {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .quote-documents-container {
          .document-wrapper-0 {
            padding-right: 0;
            padding-left: 0;
          }

          .document-wrapper-1 {
            padding-right: 0;
            border-right: 3px solid $color23;
            padding-left: 0;
            border-left: 3px solid $color23;
          }

          .document-wrapper-2 {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .benefit-header {
          text-align: center;
          background-color: $color23;
          padding: 10px 0 10px 0;

          @media all and (max-width: $screen-xs) {
            font-size: 1.4rem;
          }

        }

        .benefit-body {
          text-align: center;
          padding: 10px 0 10px 0;

          @media all and (max-width: $screen-xs) {
            font-size: 1.4rem;
          }
        }

        .benefit-body-wrapper-0 {
          padding-right: 0;
          padding-left: 0;
        }

        .benefit-body-wrapper-1 {
          padding-right: 0;
          border-right: 3px solid $color23;
          padding-left: 0;
          border-left: 3px solid $color23;
        }

        .benefit-body-wrapper-2 {
          padding-left: 0;
          padding-right: 0;
        }

        .more-info-wrapper-0 {
          padding-right: 0;
          padding-left: 0;
          padding-top: 15px;
          border-top: 3px solid $color23;
        }

        .more-info-wrapper-1 {
          padding-right: 0;
          border-right: 3px solid $color23;
          padding-left: 0;
          border-left: 3px solid $color23;
          padding-top: 15px;
          border-top: 3px solid $color23;
        }

        .more-info-wrapper-2 {
          padding-right: 0;
          padding-left: 0;
          padding-top: 15px;
          border-top: 3px solid $color23;
        }

        span {
          color: $headings-color;
        }

        input {
          display: block;
          opacity: 0;
        }

        .has-error {

          label {
            color: $color10 !important;
          }

          .help-block {
            display: none;
          }
        }

        .select-quote-btn.disabled {
          cursor: not-allowed;
          background: $answer-btn-color-2;
        }

        label {
          width: 100%;
          padding: 0;

          .select-quote-btn {
            background: $light-blue-btn-gradient;
            border: $light-blue-btn-border;
            border-radius: $radius-small;
            color: $color4;
            padding: 10px 0;
            text-transform: capitalize;
            cursor: pointer;
            transition: .4s ease;
            margin-bottom: 15px;
            margin-top: 15px;

            span {
              margin-left: 10px;
              transition: $transition;
              color: $color4;
            }

            @media all and (max-width: $screen-xs) {
              font-size: 1rem;
            }

            //&:hover {
            //  box-shadow: $btn-inset-shadow;
            //}
          }

          input:checked + .select-quote-btn {
            opacity: 1;
            background: $btn-primary-gradient;
            border: 1px solid $btn-primary-border;
            color: $white-bg;

            span {
              color: $white-bg;
              transition: $transition;
            }

            //&:hover {
            //  box-shadow: none;
            //}
          }

          input:focus + .select-quote-btn {
            box-shadow: 0 0 3px $input-button-color;
          }
        }

        .quote-section {
          border-top: 1px solid $border-color;
          padding: 6px 0;
          min-height: 68px;

          span {
            color: $available-quotes-info-button-color;
            font-size: .8em;
            cursor: pointer;
          }

          h4 {
            margin-bottom: 0;
            background-color: $color4;
          }
        }

        .more-info-btn {
          border: $white-btn-border;
          background: $white-btn-gradient;
          border-radius: $radius-small;
          color: $color10;
          font-weight: normal;
          cursor: pointer;
          padding: 10px 15px;
          transition: $transition;

          @media all and (max-width: $screen-xs) {
            font-size: 1rem;
          }

        }

      }

      .sticky-container-prices {
        height: 100%;
        z-index: 1000;
        overflow: hidden;
        max-width: $max-width-page-container;
        position: absolute;
        width: 100%;
        pointer-events: none;
      }
    }
  }
}

.quote-documents {

  .btn-documents {
    border: $white-btn-border;
    background: $white-btn-gradient;
    border-radius: $radius-small;
    color: $color10;
    font-weight: normal;
    cursor: pointer;
    padding: 5px 15px;
    width: 100%;
    margin-bottom: 15px;
    white-space: normal;
    text-align: center;

    @media all and (max-width: $screen-xs) {
      font-size: 1rem;
    }

    @media all and (max-width: 380px) {
      padding: 5px 10px;
    }

    @media all and (max-width: 320px) {
      padding: 5px 3px;
      font-size: 0.7rem;
    }
  }
}

