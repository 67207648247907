@import '../../../../styles/config/variables';

.summary-info {
  font-weight: bold;
  margin: 15px 0;

  .info {
    color: $color2;
    text-transform: capitalize;
    text-align: left;
  }
}

.edit-quote-button {
  @media all and (max-width: $screen-xs) {
    font-size: 14px;
    width: 150px;

    svg  {
      display: none;
    }
  }

  @media all and (max-width: $screen-xxs) {
    font-size: 12px;
    width: 125px;

  }
}

.documents-button {
  @media all and (max-width: $screen-xs) {
    font-size: 14px;

    svg  {
      display: none;
    }
  }

  @media all and (max-width: $screen-xxs) {
    font-size: 12px;
    width: 125px;

  }
}

.customer-documents {
  margin: 10px -15px;

  .document-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: auto 15px;
    background: $blue-btn-gradient;
    cursor: pointer;
    color: white;
    margin-bottom: 15px;
    border-radius: $radius-small;
    font-weight: bold;
    min-height: 99px;

    &:hover {
      box-shadow: $btn-inset-shadow;
    }

    span {
      font-size: .6em;
    }
  }

  .Quotation {
    height: 100px;

    @media all and (max-width: $screen-sm) {
      height: auto;
    }
  }
}

.return-to-account-btn {
  color: $color3;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }

  &:before {
    content: "\f0d9";
    font-family: "FontAwesome";
    margin-right: 5px;
  }
}


.section-header {
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding-bottom: 0px;
  margin: 30px auto 30px auto;

  color: $headings-color;

  .row {
    margin-left: 0;
    margin-right: 0;

    border-bottom: 1px solid $color-quaternary;
    padding-top: 15px;
    padding-bottom: 15px;

    &:first-of-type {
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
    }

    &:nth-of-type(2) {
      padding-top: 5px;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 0px -15px 10px -15px;
    padding: 15px;
    background-color: #2350A0;
    color: #FFF;
  }

  h3 {
    font-weight: bold;
    margin: 0px -15px 10px -15px;
    padding: 15px;
    background-color: #2350A0;
    color: #FFF;
  }

}

.document-area__download {
  float: right;
  svg {
    color: $screening-more-info-text-color !important;
  }

  &.dynamic {
    cursor: pointer;
  }
}

.premium-info {
  font-weight: bold;
  font-size: 3rem;
}

.detail-header-buttons {
  .document-button {
    @media all and (max-width: $screen-sm) {
      text-align: center;
    }
  }

  .card-preference {
    text-align: center;

    @media all and (max-width: $screen-sm) {
      padding: 10px 0;
    }
  }

  .edit-button {

    text-align: right;

    @media all and (max-width: $screen-sm) {
      text-align: center !important;
    }
  }
}
