@import ".././../../../../styles/config/variables";

.medical-important-container {
  background: $color26;
  padding: 20px 15px;

  .important-title {
    font-weight: bold;
  }

  .important-information {
    text-align: center;
    font-weight: bold;
    margin: 15px;
    color: #006abe;
  }

  .medical-important-modal {
    margin: auto;
    font-size: 1.2em;
  }

  ul {
    margin-left: 30px;
  }
}
