.form-horizontal {
  &.question {
    .control-label {
      padding-top: 15px;
    }
  }
}

.screening-border-bottom {
  border-bottom: #d4d4d4 solid 1px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
