@import '../../styles/config/variables';

.question-buttons {

  button {
    width: 150px;
  }

  #no-button {
    margin-left: 10px;
  }
}

.send-documents-box {
  text-align: left;
  font-size: 0.8em;
  padding-bottom: 30px;

  .section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .title {
    font-size: 1.4em;
    margin-bottom: 30px !important;
  }

  p {
    margin-top: 15px;
    font-size: 1em;
  }

  .icon {
    font-size: 2.0em;
  }

  .environment-text {
    font-size: 0.8em;
    font-weight: 500;
  }

  .radio-group {

    padding: 0;

    .radio-button {
      width: 10%;
    }

    .radio-text {
      width: 90%;
      margin: auto 0 auto 0;
      font-weight: bold;
    }

    .option {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      background-color: #FFF;
      padding: 15px;
      border-top: 1px solid $color22 !important;

      &.no-border {
        border-top: none !important;
      }
      font-size: 1.2em;
    }

    .option.renewal {
      border-top: none !important;
      padding: 0 15px 0 15px !important;
    }

    .radio-text.renewal {
      font-weight: normal;
    }
  }

  .select-option {
    font-size: 1.3em;
  }
}
