@import "../../../../styles/config/variables";

.selection-text {
  text-align: center;
}

.form-field {
  max-width: 300px;

  @media all and (max-width: $screen-sm) {
    max-width: unset;
    margin-top: 15px;
  }

}

.email-change-note {
  color: $color16;
}

.post-code-selector {
  max-width: 400px;
  @media all and (max-width: $screen-sm) {
    max-width: unset;
  }
}

.input-group-btn {
  .btn-postcode {
    min-width: 95px;

    @media all and (max-width: $screen-sm) {
      margin-top: 15px;
    }
  }
}

.cancel-header {
  background-color: $color-primary;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

.cancel-confirm-body {
  background-color: $blue-section-bg;
  color: $color-primary;
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  max-width: 700px;
  margin: 20px auto 0 auto;

  .cancel-confirm-title {
    font-weight: bold;
    padding: 10px 0 20px 0;
  }

}

.objection-images-container {
  margin: 40px 0 40px 0;
  display: flex;
  justify-content: center;

  .objection-image {
    text-align: center;
  }

}

.cancel-policy-image-text {
  font-style: italic;
  font-size: 1.5rem;
  padding-top: 15px;
}

.cancellation-cards-container {

  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 40px 0 40px 0;

  .cancellation-card-wrapper {
    margin: 0 10px 0 10px;

    &.right {
      text-align: -webkit-right;
    }

    &.center {
      text-align: -webkit-center;
    }

    @media all and (max-width: $screen-xs) {
      text-align: -webkit-center;
      margin-bottom: 20px;

      &.right {
        text-align: -webkit-center;
      }
    }

    .cancellation-discount-card {
      text-align: center;
      cursor: pointer;
      width: 170px;
      height: 190px;
      border: 3px solid $color16;
      border-radius: 20px;
      position: relative;

      &:hover {
        box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      }

      .cancellation-discount-card-header {
        height: 60px;
        background-color: $color16;
        border-top: 4px solid $color16;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .cancellation-discount-card-image {
        position: absolute;
        left: 45px;
        width: 70px;
        height: 70px;
        bottom: 128px;

        &.clock, &.globe {
          position: absolute;
          left: 45px;
          width: 70px;
          height: 55px;
          bottom: 128px;
        }

        &.cover-level {
          position: absolute;
          left: 45px;
          width: 71px;
          height: 70px;
          bottom: 122px;
        }
      }

      .header-title {
        padding-top: 5px;
        font-size: 16px;
      }

      .body-text {
        font-size: 12px;
        padding-top: 10px;
      }
    }

    .cancellation-cancel-card {
      cursor: pointer;
      text-align: center;
      width: 170px;
      height: 190px;
      border: 3px solid $color-primary;
      border-radius: 20px;
      position: relative;

      &:hover {
        box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      }

      .cancellation-cancel-card-header {
        height: 60px;
        background-color: $color-primary;
        border-top: 4px solid $color-primary;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .cancel-card-image {
        position: absolute;
        left: 54px;
        width: 55px;
        height: 55px;
        bottom: 126px;
      }

      .body-text {
        font-size: 16px;
        padding: 30px 10px 0 10px;
      }
    }
  }
}

.cancellation-cancel-selection {
  width: 80px;
  height: 100px;
  background-color: blue;
}

.cancellation-dropdown-container {
  display: flex;
  flex-wrap: wrap;

  .text {
    flex-basis: 30%;
    flex-grow: 1;
  }

  .dropdown {
    flex-basis: 70%;
    flex-grow: 1;
  }
}

.discount-refund-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  .text {
    flex-basis: 70%;
    flex-grow: 1;
    padding-top: 10px;
  }

  .button {
    flex-basis: 30%;
    flex-grow: 1;
    text-align: end;
  }
}
