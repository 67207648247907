@import '../../../../../../styles/config/variables';

.customer-area-top-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  text-align: right;
  background-color: $color-primary;
  color: #FFF;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media all and (max-width: $screen-sm) {
    padding: 3px;
  }

  &__icon {
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 5px;
    cursor: pointer;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0px;
    @media all and (max-width: $screen-sm) {
      font-size: 12px;
      padding: 0px;
      margin: auto;
    }

    li {
      margin: 0px 0px 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media all and (max-width: $screen-sm) {
        margin: 0px 0px 0px 10px;
      }

      a {
        color: #FFF;

        &:hover {
          color: #FFF;
        }
      }
    }
  }

}
