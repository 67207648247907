@import '../../styles/config/variables';

.info-annie-container {
  padding: 12px 10px;
  border-radius: 10px;
  min-height: 80px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: auto;

  &.blue {
    background-color: $admiral-info-background-color;
    color: $color1;
  }

  .annie-img {
    flex: 0 1 auto;
    font-size: 1.75em;
    line-height: 1;
    padding-right: 0.4em;

    > img {
      height: 44px;
    }
  }

  .content {
    .title {
      font-weight: bold;
      font-size: 1.8rem;

      @media all and (max-width: $screen-xs) {
        font-size: 1.6rem;
      }

      &.standalone {
        font-size: 2rem;
      }
    }

    .text {
      font-size: 1.3rem;

      @media all and (max-width: $screen-xs) {
        font-size: 1.3rem;
      }

    }
  }


}
