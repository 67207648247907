$color1: #0045A0 !default;
$color2: #4F4F4F !default;
$color3: #0078FF !default;
$color4: #FEFEFE !default;
$color5: #F0F0EB !default;
$color6: #CC0033 !default;
$color7: #FF7911 !default;
$color8: #41A5F5 !default;
$color9: #A5CDDB !default;
$color10: #282828 !default;
$color11: #464646 !default;
$color12: #D1C7BD !default;
$color13: #F7F7F5 !default;
$color14: #0B2C5B !default;
$color15: #A4CCEA !default;
$color16: #00A32E !default;
$color17: #009227 !default;
$color18: #B8B8CC !default;
$color19: #feb645 !default;
$color20: #e4f4ff;
$color21: #ececf3;
$color22: #41a5f55c;
$color23: #F0F0EC;
$color24: #F0F0EC;
$color25: #0080C2;
$color26: #e3f1fe;

$link-color1: #317AF7;

$gradient2: linear-gradient(to top, #0080CC , #00B8EA);
$answer-btn-color-1: #f1f100;
$answer-btn-color-2: #feb645;
$answer-btn-gradient: linear-gradient(to bottom, #f1f100 , #feb645);

$screen-xxs: 340px;
$screen-xs: 480px;
$screen-smx: 600px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$font-family-base: "jaf-facitweb", Helvetica, Arial, sans-serif;

$radius-small: 3px;
$radius-normal: 6px;
$radius-large: 10px;

// Bootstrap Config
//$brand-primary: $color1;

/* Global */

$color-primary: $color1;
$color-secondary: $color2;
$color-tertiary: $color3;
$color-quaternary: #C6E2E6;

$page-bg: $color4;
$border-color: $color12;
$white-bg: $color4;

$important-message-color: $color16;
$important-idol-message-color: $color20;

$screening-more-info-text-color: #41a5f5;
$screening-warning-text-color: $color6;

// Input

$input-primary-bg: $color3;
$input-primary-color: $color4;
$input-primary-hover-bg: darken($input-primary-bg, 5%);
$input-primary-hover-color: $color4;

$input-button-border-color: $color18;
$input-button-bg: $color21;
$input-button-color: $color10;

$input-button-selected-bg: $color3;
$input-button-selected-color: $color4;
$input-button-selected-border-color: $color19;

$input-error-border-color: $color7;
$input-checked-color: $color16;
$input-border-color: $color8;

// Modal

$modal-header-bg: $color1;
$modal-header-color: $color9;

// Section

$divider-color: $color22;

$content-bg: $color4;
$section-border-color: $color5;
$section-bg: $color4;
$section-important-bg: $color24;
$section-color: $color2;

$blue-section-bg: $color20;
$blue-section-border-color: $color8;
$blue-section-color: $color1;

$max-width-page-container: 708px;

// Typography

$headings-color: $color1;
$alternative-headings-color: $color11;

// Table

$table-bg: $color4;
$table-head-bg: $color9;
$table-head-border-color: $color2;
$table-alt-bg: $color9;

// Customer Table
$customer-table-bg: lighten($color5, 3%);

/* Header */

$header-bg: $color4;
$header-default-color: $color1;
$header-bar-color: $color3;
$header-hours-color: $color8;

// Wizard

$wizard-bg: $color4;
$wizard-color: $color1;
$wizard-item-color: $wizard-color;
$wizard-item-hover: lighten($color1, 5%);
$wizard-item-badge-color: $color4;
$wizard-item-badge-bg: $color3;

$wizard-disable-color: $color1;
$wizard-step-color: $color9;

// Hero

$hero-start: rgba($color3, 0.7);
$hero-end: transparent;
$hero-color: $color7;

/* Footer */

$footer-bg: $color11;
$footer-color: $color4;

/* Quote Summary */

$quote-summary-bg: $color9;
$quote-summary-premium-bg: lighten($color6, 15%);
$quote-summary-premium-color: $color1;
$quote-summary-traveller-conditions-highlight-color: $color3;

/* Trip Details */

// Travellers

$travellers-bg: $color4;

/* Medical Declaration */

// Questions

$questions-bg: $color4;
$questions-bg-alt: $color9;

/* Scheme Choice */

// Available Quotes

$available-quotes-header-color: $white-bg;
$available-quotes-header-bg: $modal-header-bg;
$available-quotes-border-color: $border-color;
$available-quotes-bg: $header-bg;
$available-quotes-info-color: $color1;

$available-quotes-active-border-color: $color16;

$available-quotes-info-button-color: $color8;

/* Payment */

// Text box

$payment-text-me-bg: $color9;
$payment-text-me-color: $color5;
$payment-text-me-icon-color: $color1;

/* Complete */

$complete-policy-number-highlight-color: $color1;

// @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";

$transition: .3s ease;

/* Buttons */

$btn-primary-border: $color17;
$btn-primary-gradient: linear-gradient(to top, #00A32E , #00C535);

$white-btn-border: 1px solid $color18;
$white-btn-gradient: linear-gradient(to top, #E0E0E7 , #F8F8F8);

$blue-btn-border: 1px solid $color14;
$blue-btn-gradient: linear-gradient(to top, #11448C , #2350A0);
$blue-btn-color: $color9;

$light-blue-btn-border: 1px solid $color25;
$light-blue-btn-gradient: $gradient2;

$yellow-btn-gradient: $color8;
$yellow-btn-border: 1px solid $color25;

$btn-inset-shadow: inset 0 1px 8px rgba(0,0,0,0.5);

$admiral-button-secondary: $color17;
$admiral-button-primary: #006dcc;
$admiral-font-weight-bold: 700;

/* li */

$li-icon-color: $color8;

/* errors */

$error-bg: $color7;
$error-block-color: $color4;


//cookie and privacy container

$cookieAndPrivacyBg: $color11;
$cookieAndPrivacyColor: $color13;
$cookieAndPrivacyLinkColor: $color7;
$cookieAndPrivacyButtonBg: $btn-primary-gradient;


// Navbar

$navbar-border-radius: 0px;
$navbar-default-link-color: $color-primary;
$navbar-default-link-active-color: $color-tertiary;
$navbar-default-link-hover-color: $color-tertiary;

$admiral-info-background-color: #e3f1fe;
