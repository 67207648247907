@import '../../../../../../styles/config/variables';

.checkbox-container{
  .checkbox-element {
    .radio-container {
      &.inline {
        display: inline-block;
      }
    }

    input {
      opacity: 0;
      margin: 0;
    }

    .form-radio {
      padding: 7px;

      label {
        padding-left: 0;
        font-weight: bold;

        .custom-checkbox {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: $white-bg;
          margin-right: 10px;
          margin-bottom: -6px;
          border: 1px solid #B8B8CC;
          position: relative;
          z-index: 1;

          &:after {
            opacity: 0;
            content: '';
            background: url("../../../../../Quote/img/checkbox-tick.svg") center center no-repeat;
            font-size: 1.5em;
            color: $input-checked-color;
            text-align: center;
            padding: 0;
            width: 32px;
            height: 37px;
            position: absolute;
            left: 1px;
            bottom: -3px;
            transition: 0.2s ease;
            line-height: 17px;
          }
        }

        input:checked + div::after {
          opacity: 1;
        }

        input:focus + div {
          box-shadow: 0 0 3px $input-button-color;
        }
      }
    }
  }

}
