@import ".././../../../styles/config/variables";


.greetings {
  color: $color1;

  .customer-name {
    font-size: 2em;
  }

  .greeting-1 {
    font-size: 1.5em;
  }

  .greeting-2 {
    font-size: 0.8em;
  }
}

.section-space {
  padding-top: 0;
}

.declaration-confirm {
  border: 1px solid $section-border-color;
  border-radius: $radius-small;
  margin-bottom: 15px;
  background: $section-bg;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}

.text-me-details-field {
  background: $payment-text-me-bg;
  color: $payment-text-me-color;
  border-radius: $radius-small;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 30px;

  .control-label {
    font-weight: normal;
  }

  svg {
    font-size: 26px;
    padding-left: 1px;
    color: $payment-text-me-icon-color;
    float: right;
    right: 0;
    top: 5px;
    position: absolute;
  }

  .text-me-radio {
    float: right;
  }
}

.payments-modal {
  width: 100%;
  min-height: 655px;
  border: 0;

  @media all and (min-width: $screen-md) {
    min-height: 600px;
  }
}

.payment-logos {
  margin-top: -15px;
  margin-bottom: 15px;

  @media all and (max-width: $screen-xs) {
    text-align: center;
  }

  img {
    width: 100%;

    @media all and (max-width: $screen-xs) {
      width: 60%;
    }
  }
}

.send-documents-box {

  .radio-group .radio-button {
    &.selection {
      width: 50% !important;

      .radio {
        text-align: right !important;
      }
    }
  }

  .radio-group .radio-text {
    margin: auto 0 auto 0;

    &.selection {
      width: 50% !important;
    }
  }
}

.permissions-box {
  text-align: left;
  font-size: 0.8em;

  .title {
    font-size: 1.4em;
    margin-bottom: 30px !important;
  }

  p {
    margin-top: 15px;
    font-size: 1em;
  }

  .icon {
    font-size: 2.0em;
  }

  .environment-text {
    font-size: 0.8em;
    font-weight: 500;
  }

  .radio-group {

    padding: 0;

    .radio-button {
      width: 10%;
    }

    .radio-text {
      width: 90%;
      margin: auto 0 auto 0;
    }

    .option {
      display: inline-flex;
      width: 100%;
      background-color: #FFF;
      padding: 15px;
      border: 1px solid #D1C7BD;
      font-size: 1.2em;
    }
  }

  .select-option {
    font-size: 1.3em;
  }
}

@media all and (max-width: $screen-xs) {
  .benefits {
    font-size: 13px;
  }
}

.purchase-button {
  width: 193px;
}

.radio-group {
  .option {
    border-radius: 3px;
  }
}

.payment-total-price {

  .total-pay-text {
    b {
      @media all and (max-width: 400px) {
        font-size: 0.8em;
      }
    }
  }

  span {
    font-size: 1.5em;
    color: $color1;
  }
}

.duplicate-warning {
  color: $color1;
  background: $color26;
  font-weight: bold;
  padding: 10px;
}
