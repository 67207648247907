@import '../../styles/config/variables';

.ahib--container {
  position: relative;
  margin-top: 10px;

  .ahib--overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media all and (max-width: $screen-xs) {
      height: 100%;
    }

    display: flex;
    flex-wrap: wrap;

    padding: 15px;

    .ahib--box {
      flex-basis: 50%;
      flex-grow: 1;
      min-width: 250px;
    }

    .ahib--greeting {
      font-size: 16px;
      @media all and (max-width: $screen-xs) {
        font-size: 14px;
      }
    }

    .ahib--main-text {
      font-size: 23px;
      @media all and (max-width: $screen-xs) {
        font-size: 20px;
      }
    }

    .ahib--main-button-container {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }

    .ahib--footer {
      margin-top: 5px;
      font-size: 11px;
      opacity: 0.8;
      max-width: 75%;
      @media all and (max-width: $screen-xs) {
        max-width: 60%;
      }
    }

    .ahib--error {
      margin-top: 20px;
      max-width: 75%;
    }
  }
}
