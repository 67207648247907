@import '../../styles/config/variables';

.card-details-helper {
  background-color: #edf7ff;
  border-left: solid 2px $color1;
  padding: 10px;
  margin: 0 15px 0 15px;

  svg {
    padding-top: 4px;
  }
}
