@import '../../../../../../styles/config/variables';


.document-area {

  table {
    table-layout: auto;
  }

  &__download {

    svg {
      font-size: 30px;
      color: $color-primary;
    }
  }

  .download-container {
    display: flex;

    .view {
      width: 50%;
      text-align: right;
    }

    .download-file {
      width: 50%;
      text-align: right;
    }
  }

  @media all and (max-width: $screen-xs) {

    th {
      &#Description, &#Date {
        display: none;
      }
    }

    tbody {
      tr {
        td {
          &.document-description, &.date {
            display: none;
          }
        }
      }
    }
  }
}
