@import "../../../../../../styles/config/variables";

.more-info {
  color: $color1;
  cursor: pointer;
}

.more-info-table-header {
  border: solid 2px black;
  padding: 10px 10px;
  margin-top: 15px;
}

.more-info-table-row {
  display: flex;
  border-right: solid 2px black;
  border-bottom: solid 2px black;

  .more-info-table-column {
    padding: 10px 0 10px 10px;
    width: 50%;
    border-left: solid 2px black;
  }
}


