.offline-message-container {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2em;
    text-align: center;
  }
}
