@import '../../../../styles/config/variables';

.forgotten-password {
  text-align: right;
  margin-top: 5px;
  margin-bottom: -5px;

  a {
    color: $link-color1;
    cursor: pointer;
    text-decoration: underline;
  }
}

.link-back {
  text-align: left;
  margin-top: 5px;
  margin-bottom: -5px;

  a {
    color: $link-color1;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;

    &:before {
      content: "\f0d9";
      font-family: "FontAwesome";
      margin-right: 5px;
    }
  }
}


.login-form {
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding-bottom: 15px;
  margin: 30px auto 30px auto;
  max-width: $max-width-page-container;

  h1 {
    font-size: 26px;
  }

  .control-label {
    font-weight: normal;
    color: $color2;
  }

  .register {
    color: $link-color1;
  }
}
