@import "../../styles/config/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";

.Select-control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.has-error {
  .Select-control, .form-control {
    border-color: $input-error-border-color;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken($input-error-border-color, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(#555, 20%);
      @include box-shadow($shadow);
    }
  }
  .help-block {
    color: $input-error-border-color;
  }
  label {
    color: $input-error-border-color;
  }
}

.has-error-red {
  .Select-control, .form-control {
    border-color: $color6;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken($color6, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(#555, 20%);
      @include box-shadow($shadow);
    }
  }
  .help-block {
    color: $color6;
  }
  label {
    color: $color6;
  }
}
